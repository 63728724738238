export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/admin": [4],
		"/admin/areas": [~5],
		"/admin/workers": [~6],
		"/blog": [~7],
		"/blog/[...slug]": [~8],
		"/contribute/step-1": [~9],
		"/contribute/step-2": [10],
		"/contribute/step-3": [11],
		"/contribute/step-4": [~12],
		"/contributions": [~13],
		"/contributions/[contributionId]": [~14],
		"/login": [~15],
		"/logout": [16]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';